import React from 'react'
import { Box } from 'rebass'
import Layout, { LAYOUT_TYPES } from '../modules/Layout'
import { graphql, Link } from 'gatsby'
import Main from '../modules/Main'
import useSeo from '../hooks/useSeo'
import { TEMPLATE_TYPES } from '../common/constants'
import { Heading, Text } from '@roar/components'

const SiteLinkBlocks = ({ data, heading, pathPrefix }) => (
  <Box my={3}>
    <Heading size="h3" mb={[1]}>
      {heading}
    </Heading>
    {data.map(({ node }) => (
      <Link to={`/${pathPrefix}/${node.slug}`} style={{ textDecoration: 'underline' }}>
        <Text as="p" size="body">
          {`${process.env.GATSBY_SITE_URL}/${pathPrefix}/${node.slug}`}
        </Text>
      </Link>
    ))}
  </Box>
)

const DATA_MAP = {
  title: ['name'],
}

const Sitemap = (props) => {
  const {
    data: {
      contentfulPage,
      allContentfulDestinations: { edges: destinations },
      allContentfulCharitablePartners: { edges: charitablePartners },
      allContentfulPostType: { edges: postTypes },
      allContentfulCategory: { edges: categories },
      allSitePage: { edges: sitePages },
    },
  } = props

  const url = props.location.href
  const seoProps_Page = useSeo(contentfulPage, DATA_MAP, TEMPLATE_TYPES.PAGE)
  const layout = [...contentfulPage.layout, { __typename: LAYOUT_TYPES.HELMET, ...seoProps_Page }]

  return (
    <Main {...props} path={url}>
      <Layout layout={layout} path={url} />
      <SiteLinkBlocks data={destinations} heading="Destinations" pathPrefix="destination" />
      <SiteLinkBlocks data={charitablePartners} heading="Charitable Partners" pathPrefix="responsible-travel" />

      {/* Rest of pages  */}
      <Box my={3}>
        {sitePages.map(({ node }) => {
          const paths = node.path.split('/').slice(1)
          const postTypeName = postTypes.find((pt) => pt.node.name.toLowerCase() === paths[0])
          const categoryName = categories.find((ct) => ct.node.slug === paths[1])

          if (!postTypeName || !categoryName || paths.includes('page') || paths.length > 2) return null

          return (
            <React.Fragment key={node.path}>
              {paths.length === 1 && (
                <Heading size="h3" my={[3]}>
                  {postTypeName.node.name}
                </Heading>
              )}
              <Link to={node.path} style={{ textDecoration: 'underline' }}>
                <Text as="p" size="body">{`${process.env.GATSBY_SITE_URL}${node.path}`}</Text>
              </Link>
            </React.Fragment>
          )
        })}
      </Box>
    </Main>
  )
}

export default Sitemap

export const pageQuery = graphql`
  query {
    contentfulPage(slug: { eq: "sitemap" }) {
      id
      slug
      name
      layout {
        __typename
        ... on ContentfulHero {
          ...Hero
        }
        ... on ContentfulContentGeneric {
          id
          ...ContentGeneric
        }
      }
    }
    allContentfulDestinations: allContentfulDestinations {
      edges {
        node {
          slug
        }
      }
    }
    allContentfulCharitablePartners: allContentfulCharitablePartners {
      edges {
        node {
          slug
        }
      }
    }
    allContentfulPostType {
      edges {
        node {
          name
        }
      }
    }
    allContentfulCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
    allSitePage: allSitePage(sort: { fields: path, order: ASC }) {
      edges {
        node {
          path
        }
      }
    }
  }
`
